import { breakpoints } from "@hoken/design-system/themes";
import { style } from "@vanilla-extract/css";

export const WelcomeHeroWrapper = style({
  position: "relative",
  zIndex: "3",
  alignItems: "center",
  margin: "-5.4rem 0 0 calc((100vw - 100%)/-2)",
  padding: "3rem 1rem 0",
  width: "calc(100vw)",
  backgroundImage: "url('/images/bg/hero-bg.jpg')",
  backgroundPosition: "-25vw 0",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  ":before": {
    content: "''",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.3) 100%)",
    zIndex: "-1",
  },
  "@media": {
    [breakpoints.sm]: {
      backgroundPosition: "50vw 0",
      backgroundSize: "auto",
      padding: "10rem 0 1rem",
    },
    [breakpoints.md]: {
      margin: 0,
      width: "auto",
      padding: "calc(5.5rem + var(--marquee-height)) 0 1rem",
    },
  },
});
